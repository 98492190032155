import { gql } from '@apollo/client'
import { MUTATION_STATE } from '../fragments'

const PAYLOAD_TYPE = 'InviteUserPayload'

export const INVITE_USER = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}
  mutation inviteUser(
    $agencies: [ID!]!
    $email: String!
    $priorities: [Int!]!
    $roles: [ID!]!
  ) {
    inviteUser(
      input: {
        agencies: $agencies
        email: $email
        priorities: $priorities
        roles: $roles
      }
    ) {
      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
