import api from 'api'
import { Dispatch, SetStateAction } from 'react'
import VariableUtils from 'utils/variable'
import { formErrorsDefault, InviteOrSaveUserInput, UserForm } from './types'
import { FormErrors } from 'types/various'
import ErrorUtils from 'utils/error'
import { NotifierType } from 'components/Notifier'
import UserService, { BaseRoleSelectRawData } from '../service'
import { Routes } from 'routes'
import { SelectAsyncValue } from 'components/Form/SelectAsync'
import { BaseRole } from 'types/user'
import ArrayUtils from 'utils/array'
import { CreateOrInviteUserFR, CreateOrInviteUserInput } from 'api/user'

export default class UserInviteService {
  static validate = (
    user: UserForm,
    errors: FormErrors | undefined,
    setErrors: Dispatch<SetStateAction<FormErrors | undefined>>
  ): void =>
    void setErrors({
      ...errors,
      email: [...ErrorUtils.isRequired(user.email), ...ErrorUtils.isEmail(user.email)],
      priorities: [...ErrorUtils.isRequired(user.priorities)],
      roles: [...ErrorUtils.isRequired(user.roles)],
      agencies: [...UserService.agencyNameError(user)],
      firstName: [
        ...(UserInviteService.containsPlannerOrAccountRole(user)
          ? ErrorUtils.isRequired(user.firstName)
          : []),
      ],
      lastName: [
        ...(UserInviteService.containsPlannerOrAccountRole(user)
          ? ErrorUtils.isRequired(user.lastName)
          : []),
      ],
    })

  static haveErrors = (errors: FormErrors | undefined): boolean =>
    !VariableUtils.isDeepEqual(errors, formErrorsDefault)

  static inviteUser = ({
    user,
    setLoading,
    addNotification,
    navigate,
    t,
  }: InviteOrSaveUserInput): void => {
    setLoading(true)

    UserInviteService.postMutate({
      mutation: api.user.inviteUser(UserInviteService.prepareForMutate(user)),
      addNotification,
      navigate,
      setLoading,
      t,
    })
  }

  static createUser = ({
    user,
    setLoading,
    addNotification,
    navigate,
    t,
  }: InviteOrSaveUserInput): void => {
    setLoading(true)
    const { firstName, lastName } = user

    UserInviteService.postMutate({
      mutation: api.user.createUser({
        ...UserInviteService.prepareForMutate(user),
        firstName,
        lastName,
      }),
      addNotification,
      navigate,
      setLoading,
      t,
    })
  }

  static prepareForMutate = (user: UserForm): CreateOrInviteUserInput => ({
    email: user.email,
    priorities: user.priorities,
    roles: UserInviteService.getPureRoles(user),
    agencies: user.agencies.map(agency => agency.rawData!.id),
  })

  static postMutate = ({
    mutation,
    addNotification,
    navigate,
    setLoading,
    t,
  }: { mutation: Promise<CreateOrInviteUserFR> } & Omit<
    InviteOrSaveUserInput,
    'user'
  >): Promise<void> =>
    mutation
      .then(() => {
        addNotification(NotifierType.SUCCESS, t('notification.inviteUserSuccess'))
        navigate(Routes.USERS.ALL)
      })
      .catch(() => void {})
      .finally(() => void setLoading(false))

  static containsPlannerOrAccountRole = (uf: UserForm): boolean =>
    uf.roles.length > 0 &&
    ArrayUtils.containsOneOrMoreItems(UserInviteService.getPureRoles(uf), [
      BaseRole.PLANNER,
      BaseRole.ACCOUNT,
    ])

  static getPureRoles = (userForm: UserForm): string[] =>
    userForm.roles.map((r: SelectAsyncValue<BaseRoleSelectRawData>) => r.value)
}
