import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CreationAcceptance } from '../../../../../types/campaign'
import { CreationAcceptancesSliceModel } from '../models/creation-acceptances-slice.model'

const initialState: CreationAcceptancesSliceModel = {
  loadingAcceptanceList: false,
  setDisableSendToAcceptance: false,
  acceptancesList: [],
}

const creationAcceptancesSlice = createSlice({
  name: 'creationsForm',
  initialState: initialState,
  reducers: {
    setDisableSendToAcceptance(state, action: PayloadAction<boolean>) {
      state.setDisableSendToAcceptance = action.payload
    },
    setLoadingAcceptancesList(state, action: PayloadAction<boolean>) {
      state.loadingAcceptanceList = action.payload
    },
    setAcceptancesList(state, action: PayloadAction<CreationAcceptance[]>) {
      state.acceptancesList = action.payload
    },
  },
})

export const selectAcceptancesForCreation = createSelector(
  [
    state =>
      (state as { creationAcceptances: CreationAcceptancesSliceModel }).creationAcceptances
        .acceptancesList,
    (state, creationId) => creationId,
  ],
  (acceptancesObj, creationId) => {
    return acceptancesObj.map(acceptancesObj => {
      return {
        ...acceptancesObj,
        acceptances: acceptancesObj.acceptances.filter(
          acceptanceDetails => acceptanceDetails.creationId === creationId
        ),
      }
    })
  }
)

export const selectAcceptancesAlerts = createSelector(
  [
    state =>
      (state as { creationAcceptances: CreationAcceptancesSliceModel }).creationAcceptances
        .acceptancesList,
  ],
  acceptancesObj => {
    return acceptancesObj
      .map(acceptance => {
        return acceptance.acceptances.map(item => item.alerts)
      })
      .flat(2)
  }
)

export const creationAcceptancesActions = creationAcceptancesSlice.actions

export default creationAcceptancesSlice
