import React from 'react'
import { HistoryLog } from 'types/campaign'
import DateUtils from 'utils/date'
import { userFullName } from 'utils/user'
import { DATE_HOUR_FORMAT } from 'constant'
import { Trans, useTranslation } from 'react-i18next'
import useFormattedAlters from './useFormattedAlters'
import { CampaignData } from '../../../models/campaign-data.model'

type Props = {
  campaign: Pick<CampaignData, 'creations'>
  historyLog: HistoryLog
}

const HistoryLogItem: React.FC<Props> = ({ campaign, historyLog }) => {
  const { t } = useTranslation()
  const { createdAt, alters, category, user } = historyLog
  const formattedAlters = useFormattedAlters(alters)

  const creationIdToFileName = (id: string): string | undefined =>
    campaign.creations.find(c => c.id === id)?.file.filename

  const header = (
    <div role='rowheader'>
      <Trans
        i18nKey={`campaignHistory.categories.${category}`}
        values={{
          subject: creationIdToFileName(historyLog.resourcableId),
        }}
        components={{ subject: <b /> }}
      />{' '}
      {t('campaignHistory.by')} {userFullName(user)}
    </div>
  )

  return (
    <div className='HistoryLogItem'>
      <div className='HistoryLogItem__date'>
        {DateUtils.parseAndFormat(createdAt, DATE_HOUR_FORMAT)}
      </div>
      <div className='HistoryLogItem__right'>
        <div className='HistoryLogItem__line'>{header}</div>
        {formattedAlters.map((entry, key) => (
          <div
            key={key}
            className='HistoryLogItem__line'
            role='menuitem'
          >
            <Trans
              i18nKey='campaignHistory.alter'
              values={entry.values}
              components={entry.components}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default HistoryLogItem
