import { CREATIONS_MAX_FILE_SIZE, CREATIONS_MAX_FILES } from 'constant'
import VariableUtils from 'utils/variable'

export default {
  common: {
    actions: 'Actions',
    activateCampaign: 'Activate campaign',
    advertiser: 'Advertiser',
    rejectCampaign: 'Reject campaign',
    add: 'Add',
    agency: 'Agency',
    agglomeration: 'Agglomeration',
    agreement: 'Agreement',
    alert: 'Alert',
    info: 'Info',
    alerts: ' Alerts',
    alertsNumber: 'Alerts number',
    informationsNumber: 'Informations number',
    archive: 'Archive',
    archived: 'Archived',
    archivedInfo: 'Archived, preview is possible. Reusing requires adding the creative again.',
    asId: 'AS_ID',
    back: 'Back',
    briefName: 'Brief name',
    browse: 'browse',
    budget: 'Budget',
    budgetIssued: 'Budget issued',
    building: 'Mall',
    campaign: 'Campaign',
    campaignDetails: 'Campaign details',
    campaignTerm: 'Campaign term',
    campaigns: 'Campaigns',
    cancel: 'Cancel',
    checkInbox: 'Check the inbox',
    city: 'City',
    comment: 'Comment',
    create: 'Create',
    createdAt: 'Creation date',
    scheduleDates: 'Schedules',
    createCampaign: 'Create campaign',
    createAgency: 'Create agency',
    createUser: 'Create user',
    createRole: 'Create role',
    creationList: 'Creation list',
    creationName: 'Creation name',
    agglomerationList: 'Completed contacts - division into agglomerations',
    creationDetails: 'Creation details',
    archivedAt: 'Archiving date',
    emissionStartDate: 'Date added',
    deactivate: 'Deactivate',
    dismiss: 'Dismiss',
    download: 'Download',
    edit: 'Edit',
    createCopy: 'Create copy',
    email: 'E-Mail',
    emissions: {
      a: 'emissions',
      b: 'Emissions',
      realized: 'Realized emissions',
    },
    audience: {
      a: 'audience',
      b: 'Audience',
      realized: 'Realized audience',
    },
    impressions: {
      a: 'emissions',
      b: 'Emissions',
      realized: 'Realized emissions',
    },
    emissonSchedule: 'Emission schedule',
    empty: 'empty',
    enterEmail: 'Enter e-mail',
    enterFirstName: 'Enter first name',
    enterLastName: 'Enter last name',
    enterPassword: 'Enter password',
    enterPasswordAgain: 'Re-enter the password',
    fileName: 'File name',
    filters: 'Filters',
    finished: 'Finished',
    firstName: 'First name',
    format: 'Format',
    from: 'From',
    indoor: 'Indoor',
    lastName: 'Last name',
    loading: 'Loading...',
    locations: 'Locations',
    mediaList: 'Media list',
    mediaQuantity: 'Media quantity',
    mediaDuration: 'Declared media duration',
    agglomerationOrCity: 'Agglomeration or city',
    mediaLocalization: 'Media localization',
    chosenMedia: 'Chosen media',
    mediaType: 'Media type',
    medium: 'Medium',
    mediumLocation: 'Medium location',
    name: 'Name',
    newCampaign: 'New campaign',
    no: 'No',
    note: 'Note',
    contractNote: 'Notatka kontraktowa',
    noDataFound: 'No data found',
    noData: 'No data',
    numberOfEmissions: 'Number of emissions',
    numberOfAudience: 'Number of audience',
    audienceGender: 'Audience - gender',
    audienceAge: 'Audience - age',
    audienceGenderAndAge: 'Audience - gender and age',
    numberOfAudienceEstimated: 'Number of audience (estimated)',
    distributionOfContacts: 'Daily distribution of the number of contacts',
    hiddenTarget: 'Hidden target',
    showHiddenTarget: 'Show hidden target',
    removeHiddenTarget: 'Remove hidden target',
    offerType: 'Offer type',
    ok: 'Ok',
    open: 'Open',
    or: 'or',
    outdoor: 'Outdoor',
    password: 'Password',
    pcs: 'pcs',
    placementOfMedia: 'Placement of media',
    pleaseWait: 'Please wait...',
    pois: 'POI',
    priorities: 'Priorities',
    prioritiesPlaceholder: 'Select priorities',
    priority: 'Priority',
    publishChanges: 'Publish changes',
    reason: 'Reason',
    remaining: 'Remaining',
    remove: 'Remove',
    repeatPassword: 'Repeat password',
    roles: 'Roles',
    rolesPlaceholder: 'Select roles',
    save: 'Save',
    search: 'Search',
    select: 'Select',
    selectDots: 'Select...',
    send: 'Send',
    sendToAcceptance: 'Send to acceptance',
    share: 'Share',
    show: 'Show',
    standard: 'Standard',
    status: 'Status',
    stop: 'Stop',
    supervisors: 'Supervisors',
    target: 'Target',
    targetRealization: 'Target realization',
    thumbnail: 'Thumbnail',
    to: 'To',
    units: {
      pixels: 'px',
      seconds: 'sec',
    },
    everUsedMedia: 'Media used',
    usedMedia: 'Used media',
    yes: 'Yes',
    views: 'views',
    campaignId: 'Campaign ID',
    resetPassword: 'Reset password',
    copyButton: 'Copy link',
    estimatedValue: 'Estimated value',
    currentValue: 'Current value',
    sex: 'Sex',
    ageGroup: 'Age group',
    gradation: 'Gradation',
    unit: 'Unit',
  },

  sign: {
    privacyPolicy: 'Privacy policy',
    signIn: {
      doohLogin: 'DOOH Sign in',
      checkYourEmail: 'Check your e-mail',
      checkYourPassword: 'Check your password',
      signIn: 'Sign in',
      forgotPassword: 'I forgot my password',
    },
    remindPassword: {
      doNotRemember: `Don't remember the password?`,
      enterEmail: 'Enter the e-mail address associated with the account',
      checkInboxDetails:
        'We just sent you instructions on how to change your password. Check your e-mail inbox.',
      backToSignIn: 'Back to Sign in',
    },
    resetPassword: {
      passwordReset: 'Password reset',
      enterNew: 'Enter a new password',
      resetPassword: 'Reset password',
    },
    acceptInvitation: {
      createNew: 'Create a new DOOH account',
      createAccount: 'Create an account',
      haveNotReceived: `You haven't received the email?`,
    },
  },

  targetFilters: {
    audience: 'Audience',
    impressions: 'Impressions',
  },

  sexFilters: {
    '0': 'Men',
    '1': 'Women',
  },

  ageGroupFilters: {
    '0': '0-15',
    '1': '15-30',
    '2': '30-45',
    '3': '45-60',
    '4': '60+',
  },

  status: {
    saving: 'saving',
    saved: 'saved',
    error: 'error',
  },

  topBar: {
    selectProfilePhoto: 'Select a profile photo',
    signOut: 'Sign out',
    preferredLanguage: 'Preferred language',
  },

  notification: {
    generalError: 'An error occurred',
    networkError: 'Network error',
    logInSuccess: 'Log in successfully',
    logOutSuccess: 'Log out successfully',
    accessDenided: 'Access denided',
    acceptancesUpdate: 'Acceptances updated',
    rejectedCreationsInCampaign: 'Rejected creations in campaign',
    emailSent: 'E-mail was sent',
    passwordSaveSuccess: 'Password changed successfully',
    addAgencySuccess: 'Agency successfully added',
    addRoleSuccess: 'Role successfully added',
    updateRoleSuccess: 'Role successfully updated',
    updateAgencySuccess: 'Agency successfully updated',
    updateProfileSuccess: 'User account succesfully updated',
    updateUserSuccess: 'User succesfully updated',
    inviteUserSuccess: 'User succesfully invited',
    acceptInvitationSuccess: 'Account created succesfully',
    publishChangesSuccess: 'Campaign changes successfully published',
    tooManyCreations: `Maximum number of creations to be sent at once is ${CREATIONS_MAX_FILES}. Maximum file size limit is ${VariableUtils.formatBytes(
      CREATIONS_MAX_FILE_SIZE
    )} GB. Creations have been rejected.`,
    reserveCampaignSuccess: 'Campaign successfully reserved',
    updateCampaignReservationSuccess: 'Campaign reservation successfully updated',
    confirmCampaignReservationSuccess: 'Campaign reservation successfully confirmed',
    cancelCampaignReservationSuccess: 'Campaign reservation successfully canceled',
    sendCampaignToAcceptanceSuccess: 'Campaign successfully sent to acceptance',
    exceeded70PercentMediumsAvailabilityWarning:
      'Mediums availability in cities {{ cities }} in {{ week }} week exceeded 70%',
  },

  campaigns: {
    time: 'Time',
  },

  campaignStatus: {
    sketch: 'Sketch',
    planned: 'Planned',
    active: 'Active',
    finished: 'Finished',
    paused: 'Paused',
    stopped: 'Stopped',
    reserved: 'Reserved',
    pendingAcceptation: 'W trakcie akceptacji',
    reservationCancelled: 'Cancelled reservation',
  },

  campaign: {
    realisation: 'Realisation',
    details: 'Details',
    creations: 'Creations',
    creation: 'Creation',
  },

  campaignDetails: {
    emissionTime: 'Emission time',
    locationsReport: 'Generated locations report',
    acceptancesReport: 'Generate acceptances report',
    info: {
      acceptanceFields: 'Acceptance fields',
      accepted: 'Accepted',
      notAccepted: 'Not accepted',
      noCreations: 'No creations',
      noApprovals: 'No approvals',
      notAbleToEditWhenAccepting: 'Editing is not available when pending acceptation',
    },
    chart: {
      noData: 'There is no data to generate the chart',
      sumOfEmissions: 'Sum of emissions',
      sumOfAudience: 'Sum of audience',
      sumOfAudienceEstimated: 'Sum of audience (estimated)',
      budgetForecast: 'Budget forecast',
    },
    realization: {
      header: 'Realization',
      days: 'days',
    },
    shareUrl: {
      title: 'Sharing campaign details',
      info: `Share campaign details with people who don't have access to the app. Copy the link and send it to interested people. Thanks to this, others will be able to see the same data, but not editable.`,
      downloadLink: 'Download the link',
      copyLink: 'Copy link',
    },
    creations: 'Creations',
    noCreationsInfo: 'To add creations - go to the campaign edition',
    pause: 'Pause',
    finish: 'Finish',
  },

  campaignHistory: {
    alter:
      'Attribute <attribute>{{attribute}}</attribute> has been changed from <before>{{before}}</before> to <after>{{after}}</after>',
    by: 'by',
    title: 'Campaign history',
    noData: 'There are no history logs yet',
    categories: {
      campaign_created: 'Campaign has been created',
      campaign_updated: 'Campaign has been updated',
      creation_archived: 'Creation <subject>{{subject}}</subject> has been archived',
      creation_created: 'Creation <subject>{{subject}}</subject> has been created',
      creation_deleted: 'Creation <subject>{{subject}}</subject> has been deleted',
      creation_updated: 'Creation <subject>{{subject}}</subject> has been updated',
      sent_to_brain: 'Campaign has been sent to brain',
      sent_to_acceptance: 'Campaign has been sent to acceptance',
    },
  },

  form: {
    all: 'All',
    selectAll: 'Select all',
    deselectAll: 'Deselect all',
    selected: 'Selected',
    noOptions: 'No options',
    invalidDate: 'Invalid date',
    startDateAfter: 'Start date is after than end date',
    endDateBefore: 'End date is before than start date',
    campaign: {
      edit: 'Edit campaign',
      basics: {
        briefNamePlaceholder: 'Enter brief name',
        advertiserHelp: 'Enter at least 4 characters',
        agencyHelp:
          'On the list you can see clients which are already added to the system. If you want to add/change clients go to section “clients” in the left menu.',
        agencyNamePlaceholder: 'Choose agency from the list',
        supervisorsPlaceholder: 'Choose supervisors from the list',
        endDate: 'End date',
        header: 'Basic info',
        name: 'Campaign name',
        namePlaceholder: 'Enter campaign name',
        startDate: 'Start date',
      },
      details: {
        poisHelp:
          'To specify media search - add Point of Interests (POI). Type first letter and choose from the list. You can add more than one POI. You will see search results on the map.',
        target: 'Campaign target',
        maxBudget: 'Maximum budget',
        budgetHelp:
          'Maximum budget determines the highest possible price. You will see results in the box in upper right corner. If the price is too low change campaign settings (e.g. location, POI, media type).',
        priorityHelp:
          'Priority is an information with what priority the campaign will be assigned to the available media slots. It can take values from {{  priorityMin }} to {{  priorityMax }}, where {{ priorityMax }} is the highest priority.',
        emissionsHelp: `Emissions shouldn't be higher than estimated maximum potential. Setting a higher value may result in the campaign not being 100% realized.`,
        actualPlannedTarget: 'Actual planned target.',
        offerPlaceholder: 'Select offer',
        emissionType: 'Emission type',
        synchronizedEmission: 'Synchronized emission',
        synchronizedEmissionHelp:
          'Synchronized emission: allows displaying same content on multiple media',
      },
      summary: {
        estimatedEmissions: 'Estimated maximum potential',
        estimatedEmissionsV2: 'Estimated maximum potential (V2)',
        recalculate: 'Recalculate',
        audience: 'Estimated views quantity',
      },
      creations: {
        invalidCreationEmissions: 'Invalid emissions number',
        creationEmissions: 'Creation emissions number',
        creationAudience: 'Creation audience number',
        emissionRealization: 'Emission realization',
        audienceRealization: 'Audience realization',
        creationsEmissionsNumberCantBeLowerThanCampaigns:
          'Creations total emissions is lower than campaign emissions',
        creationsEmissionsNumberCantBeHigherThanCampaigns:
          'Creations total emissions cannot be higher than campaign emissions',
        creationsEmissionsNumberShouldBeEqualToCampaigns:
          'Creations total emissions shouldnt be higher or lower to campaign emissions',
        creationsAudienceNumberCantBeLowerThanCampaigns:
          'Creations total audience is lower than campaign audience',
        creationsAudienceNumberCantBeHigherThanCampaigns:
          'Creations total audience cannot be higher than campaign audience',
        creationsAudienceNumberShouldBeEqualToCampaigns:
          'Creations total audience shouldnt be higher or lower to campaign audience',
        zipInfo: 'In order to add ZIP files, please contact your account manager.',
        addCreation: 'Add creation',
        alertTop: 'There was a technical problem with the creation',
        alertSolution: 'Please contact your AMS account manager to resolve the issue.',
        header: 'Creations',
        addingCreations: 'Adding creation',
        noCreation: 'No creation',
        dropZoneOptions: {
          minWidth: 'Min. width',
          minHeight: 'Min. height',
          maxFileSize: 'Max file size',
          maxMovieLength: 'Max movie length',
          preferred: 'Preferred',
        },
        sendToAcceptance: 'Send to acceptance',
        acceptancesList: 'Acceptances list',
        agglomeration: 'Agglomeration',
        acceptances: 'Acceptances',
        accepting: 'Accepting',
        rejected: 'Rejected',
        accepted: 'Accepted',
        acceptedFormats: 'Accepted formats',
        maxFiles: 'Maximum number of files',
        maxFileDimensions: 'Maximum dimensions of file',
        maxFileSize: 'Maximum file size',
        dropHere: 'Drag the file here',
        toSelectFile: 'to select file',
        editEmission: 'Edit emission',
        duration: 'Creation emission length',
        asIdNameHasBeenSaved: 'AS_ID Name {{name}} has been saved',
        schedules: {
          selectedDates: 'Selected dates',
        },
        archive: {
          success: 'Successfully archived creation',
        },
        archiveButtonInfo:
          'Archive, the creation is moved to the archive. You will be able to preview it, while reusing it requires adding it again.',
        containsAlcohol: 'Alcohol theme',
      },
      approval: {
        success: 'The campaign was successfully activated',
        saveAsDraft: 'Save as a draft',
      },
      removal: {
        success: 'The campaign was successfully removed',
      },
      finish: {
        success: 'The campaign was successfully finished',
      },
      save: {
        success: 'The campaign was successfully saved',
      },
      reject: {
        success: 'The campaign has been rejected',
      },
      copy: {
        success: 'The campaign was successfully copied',
      },
    },
  },

  media: {
    city: 'City',
    address: 'Address',
    screenCount: 'Screen count',
    screenCountShort: 'Scr.',
  },

  map: {
    holdButton: 'Hold the',
    toZoomIn: 'button to zoom',
  },

  mediaType: {
    dgCl: 'Digital Citylight (DG_CL)',
    dgIn: 'Digital Indoor (DG_IN)',
    dgInH: 'Digital Indoor Horizontal (DG_IN_H)',
    dgInS: 'Digital Indoor Spectacular (DG_IN_S)',
    dgCs: 'Digital Cityscreen (DG_CS)',
  },

  pageNotFound: {
    notExists: 'It looks like the page with the given address does not exist',
    goToCampaigns: 'Go to the campaigns',
  },

  agencies: {
    agencies: 'Agencies',
    endAgencies: 'Agencies',
    remove: {
      success: 'Successfully removed agency',
    },
    form: {
      newAgency: 'New agency',
      editAgency: 'Edit agency',
      agencyName: 'Agency name',
      clientId: 'Client ID',
      enterAgencyName: 'Enter agency name',
      enterClientId: 'Enter client Id',
      internal: 'Internal',
      nip: 'NIP',
      street: 'Street',
      apartmentNumber: 'Apartment number',
      buildingNumber: 'Building number',
      postalCode: 'Postal code',
      city: 'City',
      crmId: 'Crm Id',
      currency: 'Currency',
      agencyOrigin: 'Agency origin',
      domestic: 'Domestic',
      maximumPotential: 'Maximum potential',
      foreign: 'Foreign',
      discount: 'Discount',
      billingAllowance: 'Billing allowance',
      mediumsOwner: 'Mediums owner',
      allocatedTime: 'Allocated time',
      mediumsAllocatedTime: 'Mediums allocated time',
    },
  },

  priority: {
    low: 'Low',
    high: 'High',
  },

  user: {
    settings: {
      header: 'Account settings',
      profile: 'Profile',
      changePassword: 'Change password',
      previousPassword: 'Previous password',
      newPassword: 'New password',
      repeatNewPassword: 'Repeat new password',
      enterPreviousPassword: 'Enter previous password',
      enterNewPassword: 'Enter new password',
      reEnterNewPassword: 'Re-enter new password',
    },
    all: {
      users: 'Users',
      list: 'Users list',
      role: 'Role',
    },
    roles: 'Roles',
    status: {
      active: 'Active',
      deactivated: 'Deactivated',
      invited: 'Invited',
      subscription: 'Subscription',
    },
    edit: {
      header: 'Edit user',
    },
    invite: {
      header: 'New user',
      sendInvitation: 'Send an invitation',
    },
    confirm: {
      header: 'Confirmation',
      description:
        "Changing the available priorities may affect that user's campaign settings. Do you want to save changes?",
    },
    resetPassword: {
      header: "The user's password has been reset",
      description: 'Link to activate the new password',
      placeholder: 'Link to activate the new password',
    },
    reInviteModal: {
      header: 'Invitation has been sent',
      description: 'Link to activate the account',
      placeholder: 'URL',
      action: 'Send invitation again',
    },
    changeEmailModal: {
      action: 'Change e-mail',
      header: 'Change user e-mail',
      topLabel: 'Current e-mail',
      bottomLabel: 'Enter new e-mail',
      placeholder: 'xyz@gmail.com',
      error: 'E-mail is incorrect',
    },
  },

  emissonSchedule: {
    titleEdit: 'Edit emission schedule',
    duration: 'Duration',
    info: 'Here you can set the emission schedule of a given creative. If you do not select parameters in the emission schedule, the campaign will be displayed according to the general rules set in the campaign details.',
    infoA:
      'Select the duration of the broadcast in the calendar. You can select individual days and time intervals.',
    infoB: '- to select a <0>{{ single }}</0> day of broadcast, double-click the selected date',
    infoC:
      '- to select a <0>{{ timePeriod }}</0>, click the broadcast start date and the end date of the broadcast',
    infoD: '- to delete the selected day or range, click it again',
    timePeriod: 'time period',
    single: 'single',
    noMediaSelected: 'No location has been selected.',
  },

  modal: {
    doYouWant: 'Do you want to',
    doYouWantToRemove: 'Do you want to remove',
    doYouWantToStopCampaign: 'Do you want to stop campaign',
    agencyRemoval: 'Agency removal',
    creationRemoval: 'Creation removal',
    campaignRemoval: 'Campaign removal',
    campaignEditing: 'Campaign editing',
    editCampaignInfo:
      'You are editing active campaign. After changing the data, the campaign will be launched with the new parameters after 1 hour. Until then, the data based on the previous settings will be displayed.',
    editCampaignItem: 'edit active campaign',
    pauseCampaign: 'Campaign pause',
    doYouWantToPauseCampaign: 'Do you want to temporarily pause campaign',
    pause: 'Pause',
    stoppingCampaign: 'Stopping the campaign',
    stopCampaignDescription: 'Stopped campaign cannot be restarted.',
    creationArchiving: 'Creation archiving',
    archivedCreation: 'Archived creation',
    doYouWantToArchive: 'Do you want to archive creation',
    archive: 'Archive',
    doYouWantToDeactivateUser: 'Do you want to deactivate user',
    deactivationUser: 'User deactivation',
    removeRole: 'Removing role',
    doYouWantToRemoveRole: 'Do you want to remove role',
    deactivate: 'Deactivate',
    approveCampaign: 'Campaign activation',
    rejectCampaign: 'Campaign rejection',
    createCampaignCopy: 'Create campaign copy',
    doYouWantToApproveCampaign: 'Do you want to activate campaign',
    doYouWantToRejectCampaign: 'Do you want to reject campaign',
    doYouWantToCreateCampaignCopy: 'Do you want to create campaign copy',
    activate: 'Activate',
    reject: 'Reject',
    createCopy: 'Create copy',
    publishingChanges: 'Publishing changes',
    doYouWantToPublishCampaign: 'Do you want to publish changes in campaign',
    publish: 'Publish',
    reservationConfirmation: 'Reservation confirmation',
    doYouWantToConfirmReservation: 'Do you want to confirm reservation',
    reservationCancellation: 'Reservation cancellation',
    doYouWantToCancelReservation: 'Do you want to cancel reservation',
  },

  report: {
    report: 'Report',
    generatingReport: 'Generating a report',
    noData: 'No data',
    campaignReport: 'Campaign report',
    creationReport: 'Creation report',
    mediumReport: 'Medium report',
    mediaReport: 'Media report',
    campaignId: 'Campaign ID',
    mediumId: 'ID medium',
    mediaIds: 'ID mediów',
    mediaIdsHelp: 'Enter the media ID numbers after the decimal point or space',
    generate: 'Generate',
    longTimeInfo: 'It may take a while for the report to be generated.',
    dataReadInfo:
      'The results in the form of 150/100/50 we read as allocations/slots/logs. If there is only one value, it means that they are all equal.',
    dataReadInfoA:
      '- allocations - the value of allocations planned in the schedule, which are allocated to slots',
    dataReadInfoB: '- slots - the value of the planned slots on the medium',
    dataReadInfoC: '- logs - value obtained from Media Manager logs',
    massCampaignReport: 'Combined PDF campaign reports',
    firstColumn: 'Name',
    secondColumn: 'Report range',
    thirdColumn: 'Status',
    fourthColumn: 'Generation date',
    fifthColumn: 'Download url',
    pendingStatus: 'Pending',
    finishedStatus: 'Finished',
    errorStatus: 'Error',
    priceReportInfo: 'The prices in the table are net prices',
    priceReportHeader: 'Price report',
    audience: 'Audience',
    impressions: 'Impressions',
    audienceNoData: 'No audience data',
    impressionsNoData: 'No impressions data',
  },

  role: {
    admin: 'Admin',
    client: 'Customer',
    employee: 'Employee',
    planner: 'Planner',
    account: 'Account',
    support: 'Support',
  },

  errors: {
    required: 'This field is required.',
    maxSafeInteger: 'Permitted value exceeded 2 147 483 647.',
    invalidEncodingFormat: 'Invalid encoding format.',
    invalidNumericFormat: 'Invalid format. Required number.',
    invalidNumberSeparation: 'Incorrectly separated numbers.',
    invalidEmail: 'Invalid e-mail address',
    differFromEachOther: 'The field is different from the previous one.',
    maxNumberOfCharactersExceeded: 'Przekroczono maksymalną ilość znaków',
  },

  inventory: {
    title: 'Inventory',
    systemCapacity: 'System capacity',
    lastUpdate: 'Last update',
    allocatedSecondsQuantity: 'Allocated seconds quantity',
    allocatedSlotsQuantity: 'Allocated slots quantity',
    secondsQuantity: 'All seconds quantity',
    slotsQuantity: 'All slots quantity',
    plannedSecondsQuantity: 'Planned seconds quantity',
    plannedSlotsQuantity: 'Planned slots quantity',
    reservedSecondsQuantity: 'Reserved seconds quantity',
    reservedSlotsQuantity: 'Reserved slots quantity',
    freeSecondsQuantity: 'Free seconds quantity',
    freeSlotsQuantity: 'Free slots quantity',
    allocatedSecondsLabel: 'Allocated seconds',
    seconds: 'Seconds',
    slots: 'Slots',
    allocatedSeconds: 'Allocated seconds',
    reservedSeconds: 'Reserved seconds',
    plannedSeconds: 'Planned seconds',
    occupiedSeconds: 'Occupied seconds',
    occupiedSlots: 'Occupied slots',
    freeSeconds: 'Free seconds',
    priorityThreeSlots: 'Planned slots /priority "3"',
    priorityFourSlots: 'Planned slots /priority "4"',
    priorityFiveSlots: 'Planned slots /priority "5"',
    prioritySixSlots: 'Planned slots /priority "6"',
    prioritySevenSlots: 'Planned slots /priority "7"',
    freeSlots: 'Free slots',
    reservedSlots: 'Reserved slots',
    priorityThreeSeconds: 'Planned seconds /priority "3"',
    priorityFourSeconds: 'Planned seconds /priority "4"',
    priorityFiveSeconds: 'Planned seconds /priority "5"',
    prioritySixSeconds: 'Planned seconds /priority "6"',
    prioritySevenSeconds: 'Planned seconds /priority "7"',
  },

  filters: {
    filter: 'Filter',
    clearFilters: 'Clear filters',
    onlyAlerts: 'Show only with alerts',
    start: 'Start',
    end: 'End',
    periodGranulation: {
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
    },
  },

  mediumStatus: {
    active: 'Active',
    inactive: 'Inactive',
    not_operating: 'Not operating',
    removed: 'Removed',
  },

  pdf: {
    archiveReason: 'Archive reason',
    page: 'page',
    campaignSummary: 'Campaign summary',
    campaignStatus: 'Campaign status',
    number: 'No.',
    campaignName: 'Campaign name',
    plannedContacts: 'Planned contacts',
    completedContacts: 'Completed contacts',
    notCompleteData:
      '* You are faster than our agile algorithms :) Demographic data regarding the campaign will be available 3 days after its end.',
    male: 'Male',
    female: 'Female',
  },

  reservation: {
    reserve: 'Reserve',
    edit: 'Editing reservation',
    title: 'Selection of reservation date',
    info: 'The reservation is valid until 6.00 p.m. on the day selected in the calendar',
    disabledInfo: 'Unable to create reservation for campaign starting today',
    confirmReservation: 'Confirm reservation',
    cancelReservation: 'Cancel reservation',
  },

  mediaSelection: {
    title: 'Media selection',
    searchAddress: 'Search for an address',
    mediaToImport: 'Import nośników',
    mediaToImportPlaceholder: `Enter media to import. Allowed separators: space, comma, semicolon.`,
    template: {
      name: 'Name for template',
      save: 'Save as template',
      title: 'Templates',
      removeMessage: 'Remove template <template>{{template}}</template>?',
      create: {
        success: 'The template was successfully created',
      },
      remove: {
        success: 'The template was successfully removed',
      },
    },
  },

  attributesSelection: {
    title: 'Commercial/organizational attributes',
    organizationAttributes: 'Organizational attributes',
    commercialAttribute: 'Commercial attribute',
    commercialAttributes: 'Commercial attributes',
    social: 'Social (including self-promotion)',
    commercial: 'Commercial content',
    outsidePh: 'Outside PH',
    complaint: 'Complaint',
    freeComplaint: 'Free complaint',
    complaintDetail: 'Enter campaign id',
    productOffer: 'Product offer',
    commercialAttributesValues: {
      best_effort: 'Best effort',
      automatic_guarantee: 'Direct Guarantee',
    },
  },

  mediumGroup: {
    AGGLOMERATION: 'agglomeration',
    CITY: 'city',
    BUILDING: 'mall',
  },

  offer: {
    run_on_network: 'Run on network/RON',
    agglomerations: 'Agglomerations',
    buildings: 'Malls',
    media_selection: 'Media selection',
  },
  roles: {
    form: {
      newRole: 'New role',
      editRole: 'Edit role',
      baseRole: 'Base role',
    },
    privileges: {
      title: 'Privileges',
    },
  },
}
