import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Button, { ButtonTheme } from 'components/Form/Button'
import Card from 'components/Layout/Card'
import FormColumn from 'components/Form/FormColumn'
import FormRow from 'components/Form/FormRow'
import Input from 'components/Form/Input'
import SelectAsync, { SelectAsyncValue } from 'components/Form/SelectAsync'
import { AppContext } from 'contexts/AppContext'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Agency } from 'types/agency'
import { FormErrors, UserForm } from './types'
import { ReactRouterParams } from 'types/various'
import { BaseRole, UserStatus } from 'types/user'
import UserService, { BaseRoleSelectRawData } from '../service'
import service from './service'
import '../User.scss'
import { PRIORITIES } from '../../../constant'
import RangeSlider from 'components/Form/RangeSlider'
import UserChangeConfirmationModal from '../UserChangeConfirmationModal'
import ResetPasswordModal from '../ResetPasswordModal'
import ReInviteModal from '../ReinviteModal'
import ChangeEmailModal from '../ChangeEmailModal'

const UserEdit: React.FC = () => {
  const { addNotification, updateUser } = useContext(AppContext)
  const { t } = useTranslation()
  const { id: urlId } = useParams<ReactRouterParams>()
  const [user, setUser] = useState<UserForm>()
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<FormErrors>()
  const [priorities, setPriorities] = useState<number[]>([])
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const [isResetModalOpen, setIsResetModalOpen] = useState<boolean>(false)
  const [resetUrl, setResetUrl] = useState<string | undefined>(undefined)
  const [reInviteModalOpen, setReInviteModalOpen] = useState<boolean>(false)
  const [invitationUrl, setInvitationUrl] = useState<string | undefined>(undefined)
  const [changeEmailModalOpen, setChangeEmailModalOpen] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (urlId) service.getUser(urlId, setUser, setLoading)
  }, [urlId])

  useEffect(() => {
    if (!service.haveErrors(errors) && user) {
      service.updateUser(user, setLoading, addNotification, navigate, updateUser, t)
    }
  }, [errors])

  useEffect(() => {
    if (!user || priorities.length) {
      return
    }

    setPriorities(user.priorities)
  }, [priorities, user])

  const hasPrioritiesBeenChanged = (): boolean => {
    if (!user || !user.priorities) {
      return false
    }

    return (
      user.priorities[0] !== priorities[0] ||
      user.priorities[user.priorities.length - 1] !== priorities[priorities.length - 1]
    )
  }

  const onSave = (user: UserForm) => void service.validate(user, errors, setErrors)
  const Form = (user: UserForm): ReactNode => (
    <>
      <div className='User__form'>
        <Card title={t('user.edit.header')}>
          <FormRow>
            <FormColumn>
              <Input
                id='firstName'
                title={t('common.firstName')}
                placeholder={t('common.enterFirstName')}
                value={user.firstName}
                onChange={firstName => void setUser({ ...user, firstName })}
                disabled={loading}
                errors={errors?.firstName}
              />
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn>
              <Input
                id='lastName'
                title={t('common.lastName')}
                placeholder={t('common.enterLastName')}
                value={user.lastName}
                onChange={lastName => void setUser({ ...user, lastName })}
                disabled={loading}
                errors={errors?.lastName}
              />
            </FormColumn>
          </FormRow>

          <FormRow>
            <RangeSlider
              value={[user.priorities[0], user.priorities[user.priorities.length - 1]]}
              min={PRIORITIES[0]}
              max={PRIORITIES[PRIORITIES.length - 1]}
              title={t('common.priority')}
              help={t('form.campaign.details.priorityHelp')}
              onChange={priorities => {
                void setUser({
                  ...user,
                  priorities: UserService.convertScopeToRange(priorities as [number, number]),
                })
              }}
              id={'RangeSlider'}
              minLabel={t('priority.low')}
              maxLabel={t('priority.high')}
            ></RangeSlider>
          </FormRow>

          <FormRow>
            <SelectAsync
              id='roles'
              title={t('common.roles')}
              placeholder={t('common.rolesPlaceholder')}
              value={user.roles}
              onChange={roles => {
                user.agencies = []
                setUser({
                  ...user,
                  roles,
                  agencies: user.agencies,
                })
              }}
              options={(): Promise<SelectAsyncValue<BaseRoleSelectRawData>[]> =>
                UserService.getRoles()
              }
              multi
              closeOnSelect={false}
              disabled={loading}
              errors={errors?.roles}
            />
          </FormRow>

          <FormRow>
            <SelectAsync
              id='agencies'
              title={t('common.agency')}
              value={user.agencies}
              multi={user.roles.some(
                role =>
                  role.rawData?.baseRole === BaseRole.ADMIN ||
                  role.rawData?.baseRole === BaseRole.EMPLOYEE
              )}
              options={(inputValue: string): Promise<SelectAsyncValue<Agency>[]> =>
                UserService.getAgencies(inputValue)
              }
              onChange={(agencies): void =>
                void setUser({ ...user, agencies: Array.isArray(agencies) ? agencies : [agencies] })
              }
              disabled={UserService.shouldDisableClientInput(user) || loading}
              errors={errors?.agencies}
            />
          </FormRow>

          <FormRow>
            <FormColumn>
              <Input
                id='email'
                title={t('common.email')}
                placeholder={t('common.enterEmail')}
                value={user.email}
                onChange={() => void {}}
                disabled
              />
            </FormColumn>
          </FormRow>

          {user.status === UserStatus.INVITED && (
            <div className='User__actions User__actions--full'>
              <Button
                icon={loading ? faSpinner : undefined}
                disabled={loading}
                onClick={async () => {
                  setLoading(true)
                  const url = await service.reInviteUser(user.id)
                  setInvitationUrl(url)
                  setLoading(false)
                  setReInviteModalOpen(true)
                }}
              >
                {t('user.reInviteModal.action')}
              </Button>
            </div>
          )}

          {user.status === UserStatus.ACTIVE && (
            <div className='User__actions User__actions--full'>
              <Button
                icon={loading ? faSpinner : undefined}
                disabled={loading}
                onClick={async () => {
                  setChangeEmailModalOpen(true)
                }}
              >
                {t('user.changeEmailModal.action')}
              </Button>
            </div>
          )}

          <div className='User__actions'>
            <Button
              icon={loading ? faSpinner : undefined}
              disabled={loading}
              dataCy={'user-edit-save-button'}
              onClick={() => {
                if (!hasPrioritiesBeenChanged()) {
                  return onSave(user)
                }

                setIsConfirmModalOpen(true)
              }}
            >
              {t('common.save')}
            </Button>

            {user.status === UserStatus.ACTIVE && (
              <Button
                theme={ButtonTheme.BLUE_OUTLINE}
                icon={loading ? faSpinner : undefined}
                disabled={loading}
                onClick={async () => {
                  setLoading(true)
                  const url = await service.initiatePasswordReset(user.id)
                  setResetUrl(url)
                  setLoading(false)
                  setIsResetModalOpen(true)
                }}
              >
                {t('common.resetPassword')}
              </Button>
            )}
          </div>
        </Card>
      </div>

      {isConfirmModalOpen && (
        <UserChangeConfirmationModal
          isOpen={isConfirmModalOpen}
          setIsOpen={setIsConfirmModalOpen}
          save={onSave}
          user={user}
        />
      )}

      {isResetModalOpen && (
        <ResetPasswordModal
          isOpen={isResetModalOpen}
          setIsOpen={setIsResetModalOpen}
          resetUrl={resetUrl}
        />
      )}

      {reInviteModalOpen && (
        <ReInviteModal
          isOpen={reInviteModalOpen}
          setIsOpen={setReInviteModalOpen}
          invitationUrl={invitationUrl}
        />
      )}

      {changeEmailModalOpen && (
        <ChangeEmailModal
          isOpen={changeEmailModalOpen}
          setIsOpen={setChangeEmailModalOpen}
          currentEmail={user.email}
          userId={user.id}
          setUser={setUser}
          setLoading={setLoading}
        />
      )}
    </>
  )

  return <>{user ? Form(user) : t('common.noDataFound')}</>
}

export default UserEdit
