import { configureStore } from '@reduxjs/toolkit'
import campaignTableFiltersReducer from './pages/Campaign/CampaignTable/CampaignTableFilters/slice'
import detailsFormSlice from './pages/Campaign/CampaignForm/DetailsForm/store/details-form-slice'
import mapSlice from './components/Map/store/map-slice'
import creationAcceptancesSlice from './pages/Campaign/CampaignForm/CreationsForm/store/creation-acceptances-slice'
import userTableSlice from './pages/User/UserTable/store/user-table-slice'
import reportSlice from './pages/Report/store/report-slice'
import roleFormSlice from './pages/Roles/RoleForm/store/role-form-slice'
import rolesTableSlice from './pages/Roles/RolesTable/store/roles-table-slice'
import campaignSlice from './pages/Campaign/store/campaign-slice'

export const store = configureStore({
  reducer: {
    campaignTableFilters: campaignTableFiltersReducer,
    detailsForm: detailsFormSlice.reducer,
    creationAcceptances: creationAcceptancesSlice.reducer,
    map: mapSlice.reducer,
    userTable: userTableSlice.reducer,
    rolesTable: rolesTableSlice.reducer,
    roleForm: roleFormSlice.reducer,
    report: reportSlice.reducer,
    campaign: campaignSlice.reducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
