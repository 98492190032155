import Button, { ButtonSize } from 'components/Form/Button'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Summary from '../Summary'
import { EstimatedEmissionsSummaryProps } from './types'
import service from './service'
import './EstimatedEmissionsSummary.scss'

const EstimatedEmissionsSummary: React.FC<EstimatedEmissionsSummaryProps> = ({
  campaignId,
  title,
  experimental,
}) => {
  const { t } = useTranslation()
  const [estimatedEmissions, setEstimatedEmissions] = useState<number | null>(null)
  const [estimatedEmissionsLoading, setEstimatedEmissionsLoading] = useState<boolean>(false)

  const handleClick = () =>
    void service.getCampaignEstimatedEmissions({
      campaignId,
      setEstimatedEmissions,
      setEstimatedEmissionsLoading,
      experimental,
    })

  return (
    <Summary
      title={t(`form.campaign.summary.${title}`)}
      quantity={estimatedEmissions}
      isLoading={estimatedEmissionsLoading}
    >
      {campaignId && (
        <Button
          className='EstimatedEmissionsSummary__button'
          size={ButtonSize.SMALL}
          onClick={handleClick}
        >
          {t('form.campaign.summary.recalculate')}
        </Button>
      )}
    </Summary>
  )
}

export default EstimatedEmissionsSummary
