import { FormErrors } from 'types/various'
import { Priority, User } from 'types/user'
import { SelectAsyncValue } from 'components/Form/SelectAsync'
import { Agency } from 'types/agency'
import { Dispatch, SetStateAction } from 'react'
import { NavigateFunction } from 'react-router-dom'
import { UseNotifierProps } from 'hooks/useNotifier'
import { TFunction } from 'i18next'
import { BaseRoleSelectRawData } from '../service'

export type UserForm = Pick<User, 'email'> & {
  agencies: SelectAsyncValue<Agency>[]
  firstName: User['firstName']
  lastName: User['lastName']
  priorities: Priority[]
  roles: SelectAsyncValue<BaseRoleSelectRawData>[]
}

export const userFormDefault: UserForm = {
  agencies: [],
  email: '',
  firstName: '',
  lastName: '',
  priorities: [3],
  roles: [],
}

export const formErrorsDefault: FormErrors = {
  agencies: [],
  email: [],
  firstName: [],
  lastName: [],
  priorities: [],
  roles: [],
}

export interface InviteOrSaveUserInput {
  user: UserForm
  setLoading: Dispatch<SetStateAction<boolean>>
  addNotification: UseNotifierProps['addNotification']
  navigate: NavigateFunction
  t: TFunction
}
