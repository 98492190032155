import api from 'api'
import { DetailsValues, detailsValuesDefault } from 'contexts/CampaignFormContext'
import { CardStatus } from 'components/Layout/Card/types'
import {
  Campaign,
  CampaignBudgetCurrency,
  CampaignTarget,
  defaultCommercialAttribute,
  MediaFormat,
  OFFER,
} from 'types/campaign'
import EnumUtils from 'utils/enum'
import { DetailsFormErrors, detailsFormErrorsEmpty } from '../types'
import VariableUtils from 'utils/variable'
import { SelectOption } from 'components/Form/Select'
import i18n from 'i18n'
import ErrorUtils from 'utils/error'
import { BaseRole, Priority, Role, User } from 'types/user'
import ArrayUtils from 'utils/array'
import { Dispatch, SetStateAction } from 'react'
import { detailsFormActions } from '../store/details-form-slice'
import { store } from 'store'
import { campaignActions } from '../../../store/campaign-slice'
import { UpdateMediaCriteriaSearchFR } from '../../../../../api/campaign/types'
import { ADDITIONAL_BEST_EFFORT_PRIORITY } from '../../../../../constant'

export default class DetailsFormService {
  static hasErrors = (errors: DetailsFormErrors | undefined): boolean =>
    !VariableUtils.isDeepEqual(errors, detailsFormErrorsEmpty)

  static offerOptions = (selectedMediumFormat: MediaFormat): SelectOption[] =>
    Object.values(OFFER)
      .filter(offer => {
        if (
          selectedMediumFormat === MediaFormat.DG_CL ||
          selectedMediumFormat === MediaFormat.DG_CS
        ) {
          return offer !== OFFER.BUILDINGS
        }
        return true
      })
      .map(
        (o: OFFER): SelectOption => ({
          value: o,
          label: i18n.t(`offer.${o}`),
        })
      )

  static validate = (
    values: DetailsValues,
    setErrors: Dispatch<SetStateAction<DetailsFormErrors | undefined>>
  ): void => {
    setErrors(detailsFormErrorsEmpty)

    setErrors({
      budget: ErrorUtils.isSafeInteger(values.budget),
      audience: ErrorUtils.isSafeInteger(values.audience),
      emissions: ErrorUtils.isSafeInteger(values.emissions),
      hiddenImpressions: ErrorUtils.isSafeInteger(values.hiddenImpressions),
      hiddenAudience: ErrorUtils.isSafeInteger(values.hiddenAudience),
    })
  }

  static handleSubmit = (
    campaignId: string,
    data: DetailsValues,
    setTriggerFetchCampaign: Dispatch<SetStateAction<boolean>>,
    userRoles?: Role[]
  ): Promise<void> => {
    store.dispatch(campaignActions.setCardStatus({ details: CardStatus.SAVING }))

    const mutationResult: Promise<UpdateMediaCriteriaSearchFR> =
      DetailsFormService.getUpdateMediaCriteriaSearchMutation(data, campaignId, userRoles)

    return mutationResult
      .then(() => {
        setTriggerFetchCampaign(true)
        store.dispatch(campaignActions.setCardStatus({ details: CardStatus.SAVED }))
      })
      .catch(() => {
        setTriggerFetchCampaign(false)
        store.dispatch(campaignActions.setCardStatus({ details: CardStatus.ERROR }))
      })
      .finally(() => {
        store.dispatch(detailsFormActions.setDisableMediaSelection(!data.offer))
        store.dispatch(detailsFormActions.setDisableUsedMedia(false))
      })
  }

  static fetchValues = (campaignId: Campaign['id'], values: DetailsValues): void => {
    store.dispatch(campaignActions.setCardStatus({ details: CardStatus.LOADING }))

    const query = api.campaign.getCampaignForDetailsForm(campaignId)

    query
      .then(res => {
        const {
          budget,
          budgetCurrency,
          impressions,
          hiddenImpressions,
          hiddenImpressionsActive,
          mediaCriteriaSearch,
          priority,
          acceptanceStatus,
          target,
          offer,
          commercialAttribute,
          organizationAttribute,
          emissionType,
          audience,
          hiddenAudience,
          hiddenAudienceActive,
          mediaDuration,
        } = res.data.node

        const newValues = mediaCriteriaSearch
          ? {
              mediumFormat:
                MediaFormat[
                  mediaCriteriaSearch.mediumFormat as unknown as keyof typeof MediaFormat
                ],
              pois: mediaCriteriaSearch.pois ?? [],
              target:
                CampaignTarget[
                  EnumUtils.getKeyByValue(CampaignTarget, target) as keyof typeof CampaignTarget
                ],
              budget: budget ?? 0,
              emissions: impressions ?? 0,
              hiddenImpressions: hiddenImpressions ?? 0,
              hiddenImpressionsActive,
              audience: audience ?? 0,
              hiddenAudience: hiddenAudience ?? 0,
              hiddenAudienceActive,
              emissionType,
              acceptanceStatus,
              budgetCurrency:
                CampaignBudgetCurrency[
                  EnumUtils.getKeyByValue(
                    CampaignBudgetCurrency,
                    budgetCurrency
                  ) as keyof typeof CampaignBudgetCurrency
                ],
              priority,
              mediaDuration: mediaDuration || detailsValuesDefault.mediaDuration,
              offer,
              commercialAttribute: commercialAttribute ?? defaultCommercialAttribute,
              organizationAttribute,
            }
          : {}
        const detailsValues = { ...values, ...newValues, fetched: true }

        store.dispatch(campaignActions.updateDetailsValues(detailsValues))
        store.dispatch(detailsFormActions.setDisableMediaSelection(!detailsValues.offer))
        store.dispatch(campaignActions.setCardStatus({ details: CardStatus.NONE }))
      })
      .catch(() => {
        store.dispatch(campaignActions.setCardStatus({ details: CardStatus.ERROR }))
      })
  }

  static getMinMaxFromUserPriorities = (
    userPriorities: User['priorities'],
    commercialAttribute: Campaign['commercialAttribute']
  ): { min: Priority; max: Priority } => {
    if (!commercialAttribute || commercialAttribute === 'automatic_guarantee') {
      return {
        min: ArrayUtils.getMinMax(userPriorities).min,
        max: ArrayUtils.getMinMax(userPriorities).max,
      }
    }

    return {
      min: ArrayUtils.getMinMax([ADDITIONAL_BEST_EFFORT_PRIORITY, ...userPriorities]).min,
      max: ArrayUtils.getMinMax(userPriorities).max,
    }
  }

  static getUpdateMediaCriteriaSearchMutation = (
    data: DetailsValues,
    campaignId: string,
    userRoles?: Role[]
  ): Promise<UpdateMediaCriteriaSearchFR> => {
    const {
      mediumFormat,
      hiddenImpressions,
      hiddenImpressionsActive,
      offer,
      emissionType,
      hiddenAudience,
      hiddenAudienceActive,
    } = data
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { acceptanceStatus, ...restDetailsValues } = data

    const variables = {
      ...data,
      campaignId,
      mediumFormat: EnumUtils.getKeyByValueT(MediaFormat, mediumFormat) as keyof MediaFormat,
    }

    if (userRoles && userRoles.some(role => role.baseRole === BaseRole.ADMIN)) {
      return api.campaign.updateMediaCriteriaSearchAdmin({
        ...variables,
        hiddenImpressions,
        hiddenImpressionsActive,
        offer,
        commercialAttribute: variables.commercialAttribute ?? defaultCommercialAttribute,
        emissionType,
        hiddenAudience,
        hiddenAudienceActive,
      })
    }

    if (userRoles && userRoles.some(role => role.baseRole === BaseRole.SUPPORT)) {
      return api.campaign.updateMediaCriteriaSearchSupport({
        ...variables,
        offer,
      })
    }

    if (userRoles && userRoles.some(role => role.baseRole === BaseRole.EMPLOYEE)) {
      return api.campaign.updateMediaCriteriaSearchEmployee({
        ...variables,
        commercialAttribute: variables.commercialAttribute ?? defaultCommercialAttribute,
      })
    }

    return api.campaign.updateMediaCriteriaSearch(variables)
  }
}
