import api from 'api'
import { Dispatch, SetStateAction } from 'react'
import { Campaign } from 'types/campaign'

export default class CampaignFormService {
  static getCampaignEstimatedEmissions = ({
    campaignId,
    setEstimatedEmissions,
    setEstimatedEmissionsLoading,
    experimental,
  }: {
    campaignId: Campaign['id']
    setEstimatedEmissions: Dispatch<SetStateAction<number | null>>
    setEstimatedEmissionsLoading: Dispatch<SetStateAction<boolean>>
    experimental: boolean
  }): void => {
    setEstimatedEmissionsLoading(true)

    api.campaign
      .getCampaignEstimatedImpressions(campaignId, experimental)
      .then(res => void setEstimatedEmissions(res.data.campaignEstimatedImpressions.impressions))
      .catch(() => void {})
      .finally(() => void setEstimatedEmissionsLoading(false))
  }
}
