import api from 'api'
import { Errors } from 'components/Form/InputError'
import { SelectAsyncValue } from 'components/Form/SelectAsync'
import { SelectOption } from 'components/Form/Select'
import { AGENCIES_LIMIT, PRIORITIES, PRIORITIES_SETS } from 'constant'
import i18n from 'i18n'
import { Agency } from 'types/agency'
import { Priority, BaseRole } from 'types/user'
import ArrayUtils from 'utils/array'
import { UserForm } from './UserInvite/types'

export default class UserService {
  static getRoles = (): Promise<SelectAsyncValue<BaseRoleSelectRawData>[]> =>
    api.user.getAllRoles().then(roles => {
      return roles.data.rolesForFilters.map(roleForFilters => {
        return {
          value: roleForFilters.id,
          label: roleForFilters.name,
          rawData: { baseRole: roleForFilters.baseRole },
        }
      })
    })

  static getAgencies = (input: string): Promise<SelectAsyncValue<Agency>[]> => {
    return api.agency
      .getAgencies({
        term: input,
        first: AGENCIES_LIMIT,
      })
      .then(res =>
        res.data.agencies.nodes.map(
          (agency: Agency): SelectAsyncValue<Agency> => ({
            value: agency.name,
            rawData: agency,
          })
        )
      )
      .catch(() => [])
  }

  static shouldDisableClientInput = (user: UserForm): boolean =>
    !ArrayUtils.containsOneOrMoreItems(
      user.roles.map(
        (r: SelectAsyncValue<BaseRoleSelectRawData>) => r.rawData!.baseRole as BaseRole
      ),
      [BaseRole.ADMIN, BaseRole.CLIENT, BaseRole.PLANNER, BaseRole.ACCOUNT, BaseRole.SUPPORT]
    )

  static agencyNameError = (user: UserForm): Errors =>
    UserService.shouldDisableClientInput(user)
      ? []
      : user.agencies.length === 0 &&
        user.roles.some(role => role.rawData?.baseRole !== BaseRole.ADMIN)
      ? [i18n.t('errors.required')]
      : []

  static getPrioritiesSets = (): SelectOption<Priority[]>[] =>
    PRIORITIES_SETS.map((p: Priority[]) => ({
      value: p,
      label: p.join(', '),
    }))

  static getPrioritiesRangeLabels = (): Record<
    number,
    {
      label: string
    }
  > => {
    const prioritiesObj: Record<
      number,
      {
        label: string
      }
    > = {}
    for (const key of PRIORITIES) {
      prioritiesObj[key] = { label: key.toString() }
    }

    return prioritiesObj
  }

  static convertScopeToRange = (scope: [number, number]) =>
    Array.from({ length: scope[1] - scope[0] + 1 }, (v, k) => k + scope[0])
}

export interface BaseRoleSelectRawData {
  baseRole: BaseRole
}
