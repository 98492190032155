import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from 'components/Form/Button'
import Card from 'components/Layout/Card'
import FormColumn from 'components/Form/FormColumn'
import FormRow from 'components/Form/FormRow'
import Input from 'components/Form/Input'
import SelectAsync, { SelectAsyncValue } from 'components/Form/SelectAsync'
import { AppContext } from 'contexts/AppContext'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Agency } from 'types/agency'
import { FormErrors } from 'types/various'
import { BaseRole } from 'types/user'
import ErrorUtils from 'utils/error'
import UserService, { BaseRoleSelectRawData } from '../service'
import service from './service'
import { formErrorsDefault, UserForm, userFormDefault } from './types'
import '../User.scss'
import RangeSlider from '../../../components/Form/RangeSlider'
import { PRIORITIES } from '../../../constant'

const UserInvite: React.FC = () => {
  const { addNotification } = useContext(AppContext)
  const { t } = useTranslation()
  const [user, setUser] = useState<UserForm>(userFormDefault)
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<FormErrors>()
  const navigate = useNavigate()

  useEffect(() => {
    if (!ErrorUtils.haveErrors(errors, formErrorsDefault)) {
      service[containsPlannerOrAccountRole ? 'createUser' : 'inviteUser']({
        user,
        setLoading,
        addNotification,
        navigate,
        t,
      })
    }
  }, [errors])

  const onSave = (user: UserForm) => void service.validate(user, errors, setErrors)

  const containsPlannerOrAccountRole = service.containsPlannerOrAccountRole(user)

  return (
    <div className='User__form'>
      <Card title={t('user.invite.header')}>
        <FormRow>
          <FormColumn>
            <Input
              id='email'
              title={t('common.email')}
              placeholder={t('common.enterEmail')}
              value={user.email}
              onChange={email => void setUser({ ...user, email })}
              disabled={loading}
              errors={errors?.email}
            />
          </FormColumn>
        </FormRow>

        <FormRow>
          <RangeSlider
            value={[user.priorities[0], user.priorities[user.priorities.length - 1]]}
            min={PRIORITIES[0]}
            max={PRIORITIES[PRIORITIES.length - 1]}
            title={t('common.priority')}
            help={t('form.campaign.details.priorityHelp')}
            onChange={priorities =>
              void setUser({
                ...user,
                priorities: UserService.convertScopeToRange(priorities as [number, number]),
              })
            }
            id={'RangeSlider'}
            minLabel={t('priority.low')}
            maxLabel={t('priority.high')}
          ></RangeSlider>
        </FormRow>

        <FormRow>
          <SelectAsync
            id='roles'
            title={t('common.roles')}
            placeholder={t('common.rolesPlaceholder')}
            value={user.roles}
            options={(): Promise<SelectAsyncValue<BaseRoleSelectRawData>[]> =>
              UserService.getRoles()
            }
            onChange={roles => {
              user.agencies = []
              setUser({
                ...user,
                roles,
                agencies: user.agencies,
              })
            }}
            multi
            closeOnSelect={false}
            disabled={loading}
            errors={errors?.roles}
          />
        </FormRow>

        <FormRow>
          <SelectAsync
            id='agencies'
            placeholder={t('common.rolesPlaceholder')}
            title={t('common.agency')}
            value={user.agencies}
            multi={user.roles.some(
              role =>
                role.rawData?.baseRole === BaseRole.ADMIN ||
                role.rawData?.baseRole === BaseRole.EMPLOYEE
            )}
            options={(): Promise<SelectAsyncValue<Agency>[]> => UserService.getAgencies('')}
            onChange={(agencies): void =>
              void setUser({ ...user, agencies: Array.isArray(agencies) ? agencies : [agencies] })
            }
            disabled={loading}
            errors={errors?.agencies}
          />
        </FormRow>

        {containsPlannerOrAccountRole && (
          <>
            <FormRow>
              <FormColumn>
                <Input
                  id='firstName'
                  title={t('common.firstName')}
                  placeholder={t('common.enterFirstName')}
                  value={user.firstName}
                  onChange={firstName => void setUser({ ...user, firstName })}
                  disabled={loading}
                  errors={errors?.firstName}
                />
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn>
                <Input
                  id='lastName'
                  title={t('common.lastName')}
                  placeholder={t('common.enterLastName')}
                  value={user.lastName}
                  onChange={lastName => void setUser({ ...user, lastName })}
                  disabled={loading}
                  errors={errors?.lastName}
                />
              </FormColumn>
            </FormRow>
          </>
        )}

        <div className='User__actions'>
          <Button
            icon={loading ? faSpinner : undefined}
            disabled={loading}
            onClick={() => onSave(user)}
          >
            {containsPlannerOrAccountRole ? t('common.create') : t('user.invite.sendInvitation')}
          </Button>
        </div>
      </Card>
    </div>
  )
}

export default UserInvite
