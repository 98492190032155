import { Errors } from 'components/Form/InputError'
import { SelectAsyncValue } from 'components/Form/SelectAsync'
import { Agency } from 'types/agency'
import { Priority, User } from 'types/user'
import { BaseRoleSelectRawData } from '../service'

export type UserForm = Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'status'> & {
  priorities: Priority[]
  roles: SelectAsyncValue<BaseRoleSelectRawData>[]
  agencies: SelectAsyncValue<Agency>[]
}

export interface FormErrors {
  firstName: Errors
  lastName: Errors
  priorities: Errors
  roles: Errors
  agencies: Errors
}

export const formErrorsDefault: FormErrors = {
  firstName: [],
  lastName: [],
  priorities: [],
  roles: [],
  agencies: [],
}
