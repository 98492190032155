import React, { ReactNode, useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CampaignDetailsProps, PublicViewProps } from './types'
import PageHeader from 'components/Layout/PageHeader'
import Card, { CardStatus } from 'components/Layout/Card'
import service from './services/campaign-details.service'
import creationsService from '../CampaignForm/CreationsForm/CreationsFormPreview/service'
import MediaMap from 'components/MediaMap/MediaMap'
import CampaignDetailsRealization from './CampaignDetailsRealization'
import CampaignDetailsSummary from './CampaignDetailsSummary'
import CampaignDetailsCreations from './CampaignDetailsCreations'
import FillingSpinner from 'components/FillingSpinner'
import CampaignDetailsChart from './CampaignDetailsChart'
import useFilteredMedia from 'hooks/useFilteredMedia'
import { ReactRouterParams } from 'types/various'
import CampaignUtils from 'utils/campaign'
import CardHeader from 'components/Layout/CardHeader'
import Button, { ButtonSize, ButtonTheme } from 'components/Form/Button'
import { faCopy, faEdit, faFilePdf, faShare, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Modal from 'components/Modal'
import CampaignDetailsShareUrl from './CampaignDetailsShareUrl'
import { Routes } from 'routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Campaign,
  CampaignAcceptanceStatus,
  CampaignDetails as CampaignDetailsType,
  CampaignStatus,
  CampaignTarget,
  Creation,
  CreationAcceptance,
  MediaFormat,
  Medium,
} from 'types/campaign'
import { AppContext } from 'contexts/AppContext'
import { CAMPAIGN, CAMPAIGN_ACTION, PAGES } from 'constant/authorization'
import VariableUtils from 'utils/variable'
import { Features } from 'constant/features'
import CampaignDetailsAlerts from './CampaignDetailsAlerts'
import CampaignDetailsActionModals, {
  DispatchModalAction,
  ModalActionKind,
  modalsReducer,
  modalsReducerInitialState,
} from './CampaignDetailsActionModals'
import CampaignDetailsPdf from './pdfs/CampaignDetailsPdf'
import { pdf } from '@react-pdf/renderer'
import DownloadUtils from 'utils/download'
import DateUtils from 'utils/date'
import { DATE_HOUR_FORMAT } from 'constant'
import CampaignDetailsMediaList from './CampaignDetailsMediaList'
import ArrayUtils from 'utils/array'
import CampaignDetailsNote from './CampaignDetailsNote'
import CampaignDetailsHistory from './CampaignDetailsHistory'
import './CampaignDetails.scss'
import { useDispatch, useSelector } from 'react-redux'
import { mapActions, MapSliceModel } from '../../../components/Map/store/map-slice'
import { CreationAcceptancesSliceModel } from '../CampaignForm/CreationsForm/models/creation-acceptances-slice.model'
import AcceptancesCard from '../../../components/CreationAcceptancesCard'
import useCampaignAction from '../../../hooks/useCampaignAction'
import { BaseRole } from '../../../types/user'
import { RootState, store } from '../../../store'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane'
import { campaignActions } from '../store/campaign-slice'
import { CampaignData } from '../models/campaign-data.model'
import { CampaignPermissionType, FeatureName } from 'types/features'
import { ActionButtonsHelper } from '../../../utils/action-buttons-helper'

const CampaignDetails: React.FC<CampaignDetailsProps> = ({ publicView }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { allowedFor, isFeatureActive, userData } = useContext(AppContext)
  const { campaignData, campaignDetails } = useSelector(
    (state: RootState) => state.campaign.details
  )
  const { id: urlId, uuid: urlUuid } = useParams<ReactRouterParams>()
  const navigate = useNavigate()
  const [shouldReload, setShouldReload] = useState<boolean>(false)
  const [isMediaListCollapsed, setIsMediaListCollapsed] = useState<boolean>(true)
  const [isMapCardCollapsed, setIsMapCardCollapsed] = useState<boolean>(false)
  const [isShareUrlModalOpen, setIsShareUrlModalOpen] = useState<boolean>(false)
  const { fetchCampaignMedia, mediaLoading, media } = useFilteredMedia()
  const mapLoading = useSelector(state => (state as { map: MapSliceModel }).map.loading)

  const [modalsState, dispatchModalAction] = useReducer(modalsReducer, modalsReducerInitialState)
  const { sendCampaignToAcceptance } = useCampaignAction()

  const dispatchModal: DispatchModalAction = (type, payload) =>
    dispatchModalAction({ type, payload })

  const publicViewProps: PublicViewProps = {
    campaignUuid: urlUuid,
    publicView,
  }

  const displayedMedia: Medium[] = ArrayUtils.unique(
    media.concat(campaignDetails?.everUsedMedia || []),
    (m1, m2) => m1.id === m2.id
  )

  const loadingAcceptanceList = useSelector(
    state =>
      (state as { creationAcceptances: CreationAcceptancesSliceModel }).creationAcceptances
        .loadingAcceptanceList
  )

  useEffect(() => {
    return () => {
      store.dispatch(campaignActions.resetCampaignDetails())
    }
  }, [])

  useEffect(() => {
    if (urlId || urlUuid || shouldReload) {
      service.getCampaign(urlId, publicViewProps, navigate, setShouldReload)
    }
  }, [urlId, urlUuid, shouldReload])

  useEffect(() => {
    if (campaignData) {
      fetchCampaignMedia(
        publicView ? { campaignUuid: campaignData.uuid } : { campaignId: campaignData.id }
      )
    }
    if (campaignData?.id && campaignData.mediaCriteriaSearch.mediumFormat === MediaFormat.DG_IN) {
      getAcceptancesList(campaignData.id)
    }
  }, [campaignData])

  const acceptancesList = useSelector(
    state =>
      (state as { creationAcceptances: CreationAcceptancesSliceModel }).creationAcceptances
        .acceptancesList
  )
  const getAcceptancesList = (campaignId: Campaign['id']): Promise<void> =>
    creationsService.getAcceptancesList(campaignId)

  const Status = ({
    status,
    acceptanceStatus,
    reservationTill,
  }: {
    status: Campaign['status']
    acceptanceStatus: Campaign['acceptanceStatus']
    reservationTill?: Campaign['reservationTill']
  }): ReactNode => {
    const statusParsed = CampaignUtils.parseStatus(status, acceptanceStatus)

    return (
      <div className={`CampaignDetails__status CampaignDetails__status--${statusParsed.iconTheme}`}>
        <FontAwesomeIcon
          className='CampaignDetails__status--icon'
          icon={statusParsed.icon}
        />
        {statusParsed.text}
        {status === CampaignStatus['RESERVED'] && reservationTill && (
          <div className='CampaignDetails__status--reservation'>
            {DateUtils.parseAndFormat(reservationTill, DATE_HOUR_FORMAT)}
          </div>
        )}
      </div>
    )
  }

  const Header = (campaign: CampaignData): ReactNode => {
    const status = campaign.status
    const mediumFormat = campaign.mediaCriteriaSearch.mediumFormat
    const acceptanceStatus = campaign.acceptanceStatus
    const reservationTill = campaign.reservationTill
    const isDataForPdfFetched = service.isDataForPdfFetched(campaign, campaignDetails, mediaLoading)

    const navigateToEditCampaign = () => {
      if (status === CampaignStatus.ACTIVE) {
        dispatchModal(ModalActionKind.EDIT_OPEN, true)
        return
      }

      void navigate(Routes.CAMPAIGNS.EDIT(campaign.id))
    }
    const canAdminEditCampaign =
      !allowedFor({
        template: CAMPAIGN_ACTION.EDIT_IN_PENDING_ACCEPTANCE_NOT_BY_CLIENT,
        feature: { name: FeatureName.Campaigns, action: CampaignPermissionType.Write },
        status,
      }) && campaignData?.acceptanceStatus === CampaignAcceptanceStatus.pending_acceptation

    const canClientEditCampaign =
      allowedFor({
        template: CAMPAIGN_ACTION.EDIT_ACTIVE_PLANNED_RESERVED_CAMPAIGN_BY_CLIENT,
        status,
        feature: { name: FeatureName.Campaigns, action: CampaignPermissionType.Write },
      }) && !userData.user?.roles.some(role => role.baseRole === BaseRole.ADMIN)

    const canSendToAcceptance = ActionButtonsHelper.getCanSendToAcceptance(
      allowedFor,
      status,
      userData.withAllocatedTime,
      publicView
    )

    const canFinish = ActionButtonsHelper.getCanFinish(
      allowedFor,
      status,
      userData.withAllocatedTime
    )

    return (
      <div className='CampaignDetails__header--container'>
        <PageHeader className='CampaignDetails__header'>
          {campaign.name}
          <div className='CampaignDetails__header--buttons'>
            {canSendToAcceptance && (
              <Button
                disabled={
                  campaignData?.acceptanceStatus === CampaignAcceptanceStatus.pending_acceptation ||
                  campaignData?.creations.length === 0
                }
                dataCy={'send-to-acceptance-btn'}
                theme={ButtonTheme.NAVY_BLUE_OUTLINE}
                size={ButtonSize.SMALL}
                icon={faPaperPlane}
                onClick={() => void sendCampaignToAcceptance(campaign.id)}
              >
                {t('common.sendToAcceptance')}
              </Button>
            )}

            {!publicView && allowedFor({ template: CAMPAIGN_ACTION.COPY, mediumFormat }) && (
              <Button
                dataCy={'copy-campaign-btn'}
                theme={ButtonTheme.NAVY_BLUE_OUTLINE}
                size={ButtonSize.SMALL}
                icon={faCopy}
                onClick={() => {
                  dispatchModal(ModalActionKind.CREATE_COPY_OPEN, true)
                }}
              >
                {t('common.createCopy')}
              </Button>
            )}

            {!publicView &&
              allowedFor({
                template: CAMPAIGN_ACTION.EDIT,
                feature: { name: FeatureName.Campaigns, action: CampaignPermissionType.Write },
                status,
              }) && (
                <Button
                  theme={ButtonTheme.NAVY_BLUE_OUTLINE}
                  size={ButtonSize.SMALL}
                  icon={faEdit}
                  disabled={
                    (canAdminEditCampaign || canClientEditCampaign) &&
                    !ActionButtonsHelper.getCanEditByOwner(status, userData.withAllocatedTime)
                  }
                  onClick={navigateToEditCampaign}
                >
                  {t('common.edit')}
                </Button>
              )}

            {(publicView || allowedFor({ template: CAMPAIGN_ACTION.DOWNLOAD, status })) && (
              <Button
                theme={ButtonTheme.NAVY_BLUE_OUTLINE}
                size={ButtonSize.SMALL}
                icon={faFilePdf}
                onClick={async () => {
                  if (!isDataForPdfFetched) {
                    return
                  }

                  /**
                   * 1) IMPRESSIONS
                   */
                  if (campaign.target === CampaignTarget.IMPRESSIONS) {
                    dispatch(mapActions.setLoading(true))
                    pdf(
                      <CampaignDetailsPdf
                        data={{ ...campaign, ...campaignDetails!, media: displayedMedia }}
                      />
                    )
                      .toBlob()
                      .then(blob => DownloadUtils.downloadBlob(blob, `${campaign.name}.pdf`))
                      .then(() => dispatch(mapActions.setLoading(false)))

                    return
                  }

                  /**
                   * 2) AUDIENCE
                   */
                  dispatch(mapActions.setLoading(true))

                  const createAction = await service.createCampaignSummaryPdf(campaign.uuid)

                  if (!createAction || !createAction.success) {
                    dispatch(mapActions.setLoading(false))
                    return
                  }

                  const jobId = createAction.task.jobId

                  /**
                   * a) execute service.fetchPdfTaskStatus every 5 seconds until it contains file, then stop
                   */
                  const interval = setInterval(async () => {
                    const fetchAction = await service.fetchPdfTaskStatus(jobId)

                    if (!fetchAction || fetchAction.status === 'failed') {
                      console.log('error')
                      clearInterval(interval)
                      dispatch(mapActions.setLoading(false))
                      return
                    }

                    if (fetchAction.file) {
                      clearInterval(interval)
                      dispatch(mapActions.setLoading(false))

                      await DownloadUtils.downloadFileFromUrl(
                        fetchAction.file,
                        `${campaign.name}.pdf`
                      )
                      return
                    }
                  }, 5000)

                  // if interval is not cleared, clear it after 3 minutes
                  setTimeout(() => {
                    clearInterval(interval)
                    dispatch(mapActions.setLoading(false))
                  }, 180000)
                }}
                disabled={!isDataForPdfFetched || mapLoading}
              >
                {!mapLoading ? (
                  t('common.download')
                ) : (
                  <FillingSpinner className='CampaignDetails__pdf-spinner' />
                )}
              </Button>
            )}

            {!publicView && (
              <>
                {allowedFor({ template: CAMPAIGN_ACTION.SHARE, status }) && (
                  <Button
                    theme={ButtonTheme.NAVY_BLUE_OUTLINE}
                    size={ButtonSize.SMALL}
                    icon={faShare}
                    dataCy={'campaign-details-share-campaign-btn'}
                    onClick={() => void setIsShareUrlModalOpen(true)}
                  >
                    {t('common.share')}
                  </Button>
                )}
                {allowedFor({
                  feature: { name: FeatureName.Campaigns, action: CampaignPermissionType.Delete },
                  status,
                }) && (
                  <Button
                    theme={ButtonTheme.NAVY_BLUE_OUTLINE}
                    size={ButtonSize.SMALL}
                    icon={faTrashAlt}
                    onClick={() => void dispatchModal(ModalActionKind.REMOVE_OPEN, true)}
                  >
                    {t('common.remove')}
                  </Button>
                )}
              </>
            )}
          </div>
        </PageHeader>

        <div className='CampaignDetails__header--second'>
          {Status({ status, acceptanceStatus, reservationTill })}

          {!publicView && (
            <>
              {allowedFor({
                template: CAMPAIGN_ACTION.PUBLISH,
                feature: { name: FeatureName.Campaigns, action: CampaignPermissionType.Publish },
                status,
              }) && (
                <Button
                  onClick={() => void dispatchModal(ModalActionKind.PUBLISH_OPEN, true)}
                  disabled={VariableUtils.isEmptyObject(campaign.brainState)}
                >
                  {t('common.publishChanges')}
                </Button>
              )}

              {ActionButtonsHelper.getCanApprove(
                allowedFor,
                status,
                userData.withAllocatedTime
              ) && (
                <Button onClick={() => void dispatchModal(ModalActionKind.APPROVE_OPEN, true)}>
                  {t('common.activateCampaign')}
                </Button>
              )}

              {allowedFor({
                template: CAMPAIGN_ACTION.APPROVE,
                status,
              }) &&
                acceptanceStatus === CampaignAcceptanceStatus.pending_acceptation && (
                  <Button
                    onClick={() => void dispatchModal(ModalActionKind.REJECT_OPEN, true)}
                    theme={ButtonTheme.RED_OUTLINE}
                  >
                    {t('common.rejectCampaign')}
                  </Button>
                )}

              {isFeatureActive(Features.PAUSE_CAMPAIGN) &&
                allowedFor({ template: CAMPAIGN_ACTION.PAUSE, status }) && (
                  <Button
                    theme={ButtonTheme.BLUE_OUTLINE}
                    onClick={() => void dispatchModal(ModalActionKind.PAUSE_OPEN, true)}
                  >
                    {t('campaignDetails.pause')}
                  </Button>
                )}

              {canFinish && (
                <Button
                  theme={ButtonTheme.BLUE_OUTLINE}
                  onClick={() => void dispatchModal(ModalActionKind.STOP_OPEN, true)}
                >
                  {t('campaignDetails.finish')}
                </Button>
              )}

              {allowedFor({
                template: CAMPAIGN_ACTION.CONFIRM_RESERVATION,
                status,
              }) && (
                <Button
                  theme={ButtonTheme.BLUE}
                  onClick={() => void dispatchModal(ModalActionKind.CONFIRM_RESERVATION_OPEN, true)}
                >
                  {t('reservation.confirmReservation')}
                </Button>
              )}

              {allowedFor({
                template: CAMPAIGN_ACTION.CANCEL_RESERVATION,
                status,
              }) && (
                <Button
                  theme={ButtonTheme.BLUE_OUTLINE}
                  onClick={() => void dispatchModal(ModalActionKind.CANCEL_RESERVATION_OPEN, true)}
                >
                  {t('reservation.cancelReservation')}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    )
  }

  const NoteCard = ({ note, contractNote }: CampaignData): ReactNode =>
    allowedFor({ template: CAMPAIGN.NOTE }) &&
    (note || contractNote) && (
      <CampaignDetailsNote
        note={note}
        contractNote={contractNote}
      />
    )

  const AlertsCard = (c: CampaignData): ReactNode =>
    allowedFor({ template: CAMPAIGN.ALERTS }) && <CampaignDetailsAlerts alerts={c.alerts} />

  const RealizationCard = (campaign: CampaignData): ReactNode => (
    <Card
      id='realization'
      title={t('campaignDetails.realization.header')}
      noMarginBody
    >
      <CampaignDetailsRealization
        campaign={campaign}
        publicView={publicView}
      />
      <CampaignDetailsChart
        campaign={campaign}
        displayedMedia={displayedMedia}
        publicViewProps={publicViewProps}
      />
    </Card>
  )

  const SummaryCard = (campaign: CampaignData, details: CampaignDetailsType): ReactNode => (
    <Card
      id='summary'
      title={t('common.campaignDetails')}
      noMarginBody
    >
      <CampaignDetailsSummary
        campaign={campaign}
        everUsedMediaCount={details.everUsedMedia.length}
        mediaCount={media.length}
        mediaLoading={mediaLoading}
        publicView={publicView}
      />
    </Card>
  )

  const CreationsCard = (campaignData: CampaignData): ReactNode => {
    const hasCreations = campaignData.creations.length > 0

    return (
      <Card
        id='creations'
        title={t('campaignDetails.creations')}
        noMarginBody={hasCreations}
      >
        {hasCreations ? (
          <CampaignDetailsCreations
            campaignData={{
              id: campaignData.id,
              startDate: campaignData.startDate,
              endDate: campaignData.endDate,
              status: campaignData.status,
              acceptanceStatus: campaignData.acceptanceStatus,
              mediaFormat: campaignData.mediaCriteriaSearch.mediumFormat,
              impressions: campaignData.impressions,
              target: campaignData.target,
              audience: campaignData.audience,
            }}
            creations={campaignData.creations}
            publicViewProps={publicViewProps}
            showAllMediaFormats={false}
          />
        ) : (
          <div className='CampaignDetails__no-creations-container'>
            {t('campaignDetails.noCreationsInfo')}
          </div>
        )}
      </Card>
    )
  }

  const AcceptancesCardTable = (
    acceptances: CreationAcceptance[],
    creations?: Creation[]
  ): ReactNode => {
    return (
      <AcceptancesCard
        campaignId={urlId}
        acceptances={acceptances}
        loadingAcceptances={loadingAcceptanceList}
        creations={creations}
        isCollapsible={true}
      ></AcceptancesCard>
    )
  }

  const ShareUrlModal = (c: CampaignData): ReactNode =>
    isShareUrlModalOpen &&
    c.uuid && (
      <Modal
        title={t('campaignDetails.shareUrl.title')}
        isOpen={isShareUrlModalOpen}
        onClose={(isOpen: boolean) => void setIsShareUrlModalOpen(isOpen)}
        footer={
          <Button onClick={() => void setIsShareUrlModalOpen(false)}>{t('common.finished')}</Button>
        }
      >
        <CampaignDetailsShareUrl
          url={window.location.origin + Routes.CAMPAIGNS.ONE_PUBLIC(c.uuid)}
        />
      </Modal>
    )

  const MapCard = (): ReactNode =>
    displayedMedia.length > 0 && (
      <Card
        id='map-card'
        className='CampaignDetails__map--card'
        title={t('common.placementOfMedia')}
        status={mediaLoading ? CardStatus.LOADING : CardStatus.NONE}
        isCollapsed={isMapCardCollapsed}
        onCollapse={collapsed => void setIsMapCardCollapsed(collapsed)}
        noMarginBody
      >
        {!isMapCardCollapsed && (
          <MediaMap
            className='CampaignDetails__map'
            media={displayedMedia}
            withClustering
          />
        )}
      </Card>
    )

  const MediaListCard = (campaignData: CampaignData): ReactNode => (
    <Card>
      <CardHeader
        collapsed={isMediaListCollapsed}
        onCollapse={(collapsed: boolean) => void setIsMediaListCollapsed(collapsed)}
      >
        {t('common.mediaList')}
      </CardHeader>
      {!isMediaListCollapsed && (
        <CampaignDetailsMediaList
          mediumFormat={campaignData.mediaCriteriaSearch.mediumFormat}
          media={displayedMedia}
          publicView={publicView}
        />
      )}
    </Card>
  )

  const HistoryCard = (campaign: CampaignData) =>
    allowedFor({ template: PAGES.CAMPAIGNS.HISTORY }) && (
      <CampaignDetailsHistory campaign={campaign} />
    )

  const cards = (campaign: CampaignData) => (
    <>
      {Header(campaign)}
      {NoteCard(campaign)}
      {AlertsCard(campaign)}
      {RealizationCard(campaign)}
      {campaignDetails && SummaryCard(campaign, campaignDetails)}
      {CreationsCard(campaign)}
      {acceptancesList.length > 0 &&
        campaignData?.mediaCriteriaSearch.mediumFormat === MediaFormat.DG_IN &&
        AcceptancesCardTable(acceptancesList, campaignData?.creations)}
      {MapCard()}
      {MediaListCard(campaign)}
      {ShareUrlModal(campaign)}
      {HistoryCard(campaign)}
    </>
  )

  return campaignData ? (
    <div className='CampaignDetails'>
      {cards(campaignData)}

      <CampaignDetailsActionModals
        campaign={{ id: campaignData.id, name: campaignData.name }}
        onShouldReload={setShouldReload}
        reducerState={modalsState}
        dispatch={dispatchModal}
      />
    </div>
  ) : (
    <FillingSpinner />
  )
}

export default CampaignDetails
