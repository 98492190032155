import { gql } from '@apollo/client'
import { ALERT_FIELDS, AGENCY_FIELDS, CAMPAIGN_PROGRESS_FIELDS } from '../fragments'

export const GET_CAMPAIGNS_FOR_TABLE = gql`
  ${ALERT_FIELDS}
  ${AGENCY_FIELDS}
  ${CAMPAIGN_PROGRESS_FIELDS}
  query campaignsForTable(
    $first: Int!
    $last: Int!
    $sortColumn: CampaignsSortColumnEnum
    $sortDirection: SortDirectionEnum
    $filters: CampaignFilters
  ) {
    campaigns(
      first: $first
      last: $last
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      filters: $filters
    ) {
      totalCount
      nodes {
        alerts {
          ...AlertFields
        }
        acceptanceAlerts {
          ...AlertFields
        }
        agency {
          ...AgencyFields
        }
        endDate
        id
        name
        startDate
        status
        acceptanceStatus
        progress {
          ...CampaignProgressFields
        }
        commercialAttribute
      }
    }
  }
`
