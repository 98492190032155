import { gql } from '@apollo/client'
import { MUTATION_STATE } from '../fragments'

const PAYLOAD_TYPE = 'CreateUserPayload'

export const CREATE_USER = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}
  mutation createUser(
    $agencies: [ID!]!
    $email: String!
    $firstName: String!
    $lastName: String!
    $priorities: [Int!]!
    $roles: [ID!]!
  ) {
    createUser(
      input: {
        agencies: $agencies
        email: $email
        firstName: $firstName
        lastName: $lastName
        priorities: $priorities
        roles: $roles
      }
    ) {
      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
