import Button, { ButtonTheme } from 'components/Form/Button'
import Modal from 'components/Modal'
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import './UserChangeConfirmationModal.scss'
import { UserForm } from '../UserEdit/types'

type Props = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  save: (user: UserForm) => void
  user: UserForm
}

const UserChangeConfirmationModal: React.FC<Props> = ({ isOpen, setIsOpen, save, user }) => {
  const { t } = useTranslation()

  const Footer = () => {
    return (
      <div className='Modal__footer'>
        <Button onClick={() => void save(user)}>{t('common.save')}</Button>
        <Button
          theme={ButtonTheme.BLUE_OUTLINE}
          onClick={() => void setIsOpen(false)}
        >
          {t('common.back')}
        </Button>
      </div>
    )
  }

  return (
    <Modal
      classNameChildren={'UserChangeConfirmationModal'}
      title={t('user.confirm.header')}
      isOpen={isOpen}
      onClose={setIsOpen}
      footer={Footer()}
      scrollable
    >
      <p>{t('user.confirm.description')}</p>
    </Modal>
  )
}

export default UserChangeConfirmationModal
